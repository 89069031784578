































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import CountryFlag from 'vue-country-flag';
import getCountries, {Country} from '@/plugins/Countries';

@Component({
  components: {InputText, InputGroup, Spinner, CountryFlag},
})
export default class InputDropdownCountry extends Mixins(InputMixin) {
  @Prop({type: String}) protected text?: string;

  @Prop({type: Number, default: 5}) protected numberOfSuggestions!: string[];

  protected visibleOnFocus: boolean = true;
  protected fixed: boolean = false;

  private mouseInSuggestions: boolean = false;

  private search: string | null = '';

  private countriesList: Country[] = [];
  private selectedCountry: Country | null = null;

  get open() {
    return !this.visibleOnFocus || this.focus || this.mouseInSuggestions;
  }

  get labelText(): string {
    return this.$it('global.country', 'Country') as string;
  }

  @Watch('model', {immediate: true})
  @Watch('countriesList')
  protected onModelChange(value: string | null) {
    if (value) {
      const country = this.countriesList.find((c: Country) => c.ccode === this.model);
      if (country) {
        this.selectedCountry = country;
        this.search = country.country_full_name;
      }
    }
  }

  @Watch('focus')
  protected onFocusChanged() {
    if (this.focus) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }

  private mounted() {
    if (this.autoFocus) {
      (this.$refs.input as InputText).becomeFirstResponder();
    }

    this.countriesList = getCountries();
  }

  private isStringArray(input: any) {
    if (input instanceof Array) {

      let somethingIsNotString = false;
      input.forEach((item: any) => {
        if (typeof item !== 'string') {
          somethingIsNotString = true;
        }
      });

      if (!somethingIsNotString && input.length > 0) {
        return true;
      }

    }
    return false;
  }

  private tabPressed() {
    this.$emit('tab');
  }

  private enterPressed() {
    this.$emit('enter', this.search);
  }

  @Watch('search')
  private onSearchChange(value: string) {
    this.countriesList.forEach((country: Country) => {
      if (value == null || value === '') {
        // search value is empty so it can be any country so we show all
        country.show = true;
      } else {
        // check if the search string is included in one of the countries's ccode or countrycode
        if (country.country_full_name.toLowerCase().includes(value.toLowerCase())
          || country.ccode.toLowerCase().includes(value.toLowerCase())) {
          country.show = true;
        } else {
          country.show = false;
        }
      }
    });
  }

  private select(countryCode: string, countryName: string) {
    this.selectedCountry = {
      ccode: countryCode,
      country_full_name: countryName,
    };

    this.onSelect();
  }

  private onSelect() {
    if (this.selectedCountry) {
      this.search = this.selectedCountry.country_full_name;
      this.value = this.selectedCountry.ccode;
      this.mouseInSuggestions = false;
    }
  }

}
