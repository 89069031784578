





















import {Component, Prop, Watch, Mixins} from 'vue-property-decorator';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {InputGroup},
})
export default class InputMapsAutocomplete extends Mixins(InputMixin) {
  @Prop({type: String, default: 'text'}) private type!: string;

  private line: string | null = null;

  // function returns placeholder text on focus, empty on non-focus
  get placeholder() {
    if (this.focus) {
      return this.placeholderText;
    } else {
      return '';
    }
  }

  public becomeFirstResponder() {
    (this.$refs.input as HTMLInputElement).focus();
  }

  @Watch('model', {immediate: true})
  protected onLineChange(value: any | null) {
    this.line = value;
  }

  private select(place: any) {
    this.$emit('select', place);

    // We do not want the textbox to keep its value, but the module does so
    // By using this (hacky) way of directly accessing the input, it is cleared
    this.$nextTick(() => {
      (this.$refs.input as any).$refs.input.value = '';
    });
  }

  private tabPressed() {
    this.$emit('tab');
  }

  private enterPressed() {
    this.$emit('enter');
  }
}
