

















































































































































































































import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputAutoComplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import InputGeolocation, {GeoLocation} from '@/layouts/back-office/elements/input/InputGeolocation.vue';
import InputDropdownCountry from '@/layouts/back-office/elements/input/InputDropdownCountry.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import InputMapsAutocomplete from '@/layouts/back-office/elements/input/InputMapsAutocomplete.vue';

export interface Dealer {
  id?: string;
  input?: {
    name: string;
    externalId: string;
  };
}

export interface Address {
  id?: string | number | null;
  placeId?: string | null;

  externalId: string | null;

  name: string | null;
  addressLine1: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;

  contactName?: string | null;
  email?: string | null;
  phone?: string | null;

  remark: string | null;

  latitude: number | null;
  longitude: number | null;

  auditFrequency?: number | null;
  nextAudit?: string | null;

  trustedAuditFrequency?: number | null;
  nextTrustedAudit?: string | null;

  accountManager?: any | null;

  dealer?: Dealer;
}

@Component({
  components: {
    InputMapsAutocomplete,
    InputInspector,
    InputDate, InputDropdownCountry, InputGeolocation, InputTextarea, InputText, InputAutoComplete},
})
export default class InputAddress extends Mixins(InputMixin) {
  protected value: Address | null = null;

  @Prop({type: Boolean, default: true}) protected searchable!: boolean;
  @Prop({type: Boolean, default: false}) protected changeGeolocation!: boolean;
  @Prop({type: Boolean, default: false}) protected alwaysEditable!: boolean;
  @Prop({type: Number, default: 0}) protected tabIndex!: number;

  @Prop({type: Boolean, default: false}) protected immediateChange!: boolean;
  @Prop({type: Boolean, default: false}) protected immediateCheck!: boolean;

  @Prop({type: Boolean, default: false}) protected autocompleteAddress!: boolean;

  @Prop({type: Boolean, default: true}) protected externalIdRequired!: boolean;
  @Prop({type: Boolean, default: false}) protected editPlanning!: boolean;
  @Prop({type: Boolean, default: true}) protected editContact!: boolean;
  @Prop({type: Boolean, default: false}) protected editAccountManager!: boolean;

  @Prop(Object) protected dealer?: Dealer;

  private errors: any = {};

  private loading = false;
  private modelUpdateBlock = false;

  private id: string | number | null = null;
  private placeId: string | null = null;

  private externalId: string | null = null;

  private search: string | null = null;
  private name: string | null = null;
  private addressLine1: string | null = null;
  private zipCode: string | null = null;
  private city: string | null = null;
  private state: string | null = null;
  private country: string | null = null;

  private contactName: string | null = null;
  private email: string | null = null;
  private phone: string | null = null;

  private remark: string | null = null;

  private auditFrequency: number | null = null;
  private nextAudit: string | null = null;

  private trustedAuditFrequency: number | null = null;
  private nextTrustedAudit: string | null = null;

  private accountManager: any | null = null;

  private geolocation: GeoLocation = {
    latitude: null,
    longitude: null,
  };

  get isClearable() {
    return this.clearable && this.isFilled && !this.inputDisabled;
  }

  get inputDisabled() {
    return this.loading || ((this.id !== null || this.placeId !== null) && !this.alwaysEditable);
  }

  get remarkDisabled() {
    return this.loading || (this.id !== null && !this.alwaysEditable);
  }

  get inputErrors(): any {
    const errors: any = {};

    if (this.name === null) {
      errors.name = this.$it('address.name.error.required', 'The location name is required') as string;
    }
    if (this.addressLine1 === null) {
      // tslint:disable-next-line:max-line-length
      errors.addressLine1 = this.$it('address.address-line1.error.required', 'The location address field is required') as string;
    }
    if (this.zipCode === null) {
      errors.zipCode = this.$it('address.zip-code.error.required', 'The location zip code is required') as string;
    }
    if (this.city === null) {
      errors.city = this.$it('address.city.error.required', 'The location city is required') as string;
    }
    if (this.country === null) {
      errors.country = this.$it('address.country.error.required', 'The location country is required') as string;
    }
    if (this.externalIdRequired && this.externalId === null) {
      // tslint:disable-next-line:max-line-length
      errors.externalId = this.$it('address.external-id.error.required', 'The location external id is required') as string;
    }
    if (this.email !== null && !this.$validateEmail(this.email)) {
      // tslint:disable-next-line:max-line-length
      errors.email = this.$it('address.email.error', 'The provided contact email must be a valid email address') as string;
    }

    return errors;
  }

  public check(): boolean {
    this.errors = this.inputErrors;

    return Object.keys(this.errors).length <= 0;
  }

  public silentCheck(): boolean {
    return Object.keys(this.inputErrors).length <= 0;
  }

  private created() {
    if (this.immediateChange) {
      this.$nextTick(() => {
        this.onItemChange();
      });
    }

    if (this.immediateCheck) {
      this.check();
    }
  }

  private onItemSelect(value: any) {
    if (value) {
      if (value.__typename === 'GooglePlaces') {
        this.setGooglePlaces(value);
      } else {
        this.setAddress(value);
      }
    }
  }

  @Watch('model', {immediate: true})
  private modelUpdated(address: any) {
    if (address && !this.modelUpdateBlock) {
      this.setAddress(address, false);
    }
  }

  private selectMapsAddress(place: any) {
    // Clear the search bar
    this.placeId = place.place_id;

    const addressLine = { street: null, number: null };
    for (const component of place.address_components) {
      if (component.types.indexOf('state') > -1) {
        this.state = component.long_name;
      } else if (component.types.indexOf('route') > -1) {
        addressLine.street = component.long_name;
      } else if (component.types.indexOf('street_number') > -1) {
        addressLine.number = component.long_name;
      } else if (component.types.indexOf('locality') > -1) {
        this.city = component.long_name;
      } else if (component.types.indexOf('postal_code') > -1) {
        this.zipCode = component.long_name;
      } else if (component.types.indexOf('administrative_area_level_1') > -1) {
        this.state = component.short_name;
      } else if (component.types.indexOf('country') > -1) {
        this.country = component.short_name;
      }
    }

    if ('geometry' in place && 'location' in place.geometry) {
      this.geolocation = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
    }

    this.addressLine1 = [addressLine.street, addressLine.number].filter((p) => p).join(' ');

    this.onItemChange([
      'addressLine1',
      'zipCode',
      'city',
      'state',
      'country',
      'latitude',
      'longitude',
    ]);

    this.$nextTick(() => {
      this.search = null;
    });
  }

  private setAddress(address: Address, changed: boolean = true) {
    this.clearAddress(false);

    this.modelUpdateBlock = true;

    this.id = address.id ?? null;
    this.placeId = address.placeId ?? null;

    this.externalId = address.externalId ?? null;

    this.name = address.name ?? null;
    this.addressLine1 = address.addressLine1 ?? null;
    this.zipCode = address.zipCode ?? null;
    this.city = address.city ?? null;
    this.state = address.state ?? null;
    this.country = address.country ?? null;

    this.contactName = address.contactName ?? null;
    this.email = address.email ?? null;
    this.phone = address.phone ?? null;

    this.remark = address.remark ?? null;

    if (this.editPlanning) {
      this.auditFrequency = address.auditFrequency ?? null;
      this.nextAudit = address.nextAudit ?? null;

      this.trustedAuditFrequency = address.trustedAuditFrequency ?? null;
      this.nextTrustedAudit = address.nextTrustedAudit ?? null;
    }

    if (this.editAccountManager) {
      this.accountManager = address.accountManager ?? null;
    }

    this.geolocation.latitude = (address.latitude) ? address.latitude : null;
    this.geolocation.longitude = (address.longitude) ? address.longitude : null;

    this.$nextTick(() => {
      this.modelUpdateBlock = false;
    });

    if (changed) {
      this.onItemChange();
    }
  }

  private async setGooglePlaces(address: Address) {
    this.clearAddress(false);

    this.loading = true;
    this.modelUpdateBlock = true;

    const addressByPlaceId = await this.$apollo.query({
      query: require('@/graphql/AddressByPlaceId.gql'),
      variables: {
        placeId: address.id,
      },
    });

    const data = addressByPlaceId.data.addressByPlaceId;

    this.id = null;
    this.placeId = data.id;

    this.name = data.name;
    this.addressLine1 = data.addressLine1;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;

    this.loading = false;

    this.$nextTick(() => {
      this.modelUpdateBlock = false;
    });

    this.onItemChange();
  }

  private clearAddress(changed: boolean = true) {
    this.id = null;
    this.placeId = null;

    this.externalId = null;

    this.name = null;
    this.addressLine1 = null;
    this.zipCode = null;
    this.city = null;
    this.state = null;
    this.country = null;
    this.remark = null;

    this.contactName = null;
    this.email = null;
    this.phone = null;

    this.remark = null;

    this.auditFrequency = null;
    this.nextAudit = null;

    this.accountManager = null;

    this.geolocation.latitude = null;
    this.geolocation.longitude = null;

    if (changed) {
      this.onItemChange();
    }
  }

  @Watch('dealer')
  @Emit('change')
  private onItemChange(field: string[] | string | null = null): Address {
    if (Array.isArray(field)) {
      field.forEach((f: string) => {
        this.errors[f] = null;
      });
    } else if (field) {
      this.errors[field] = null;
    }

    const data: Address = {
      id: this.id,
      placeId: this.placeId,

      externalId: this.externalId,

      name: this.name,
      addressLine1: this.addressLine1,
      zipCode: this.zipCode,
      city: this.city,
      state: this.state,
      country: this.country,

      contactName: this.contactName,
      email: this.email,
      phone: this.phone,

      remark: this.remark,

      latitude: this.geolocation.latitude,
      longitude: this.geolocation.longitude,
    };

    if (this.editPlanning) {
      data.auditFrequency = this.auditFrequency;
      data.nextAudit = this.nextAudit;

      data.trustedAuditFrequency = this.trustedAuditFrequency;
      data.nextTrustedAudit = this.nextTrustedAudit;
    }

    if (this.editAccountManager) {
      data.accountManager = null;

      if (typeof this.accountManager === 'string' && !isNaN(Number(this.accountManager))) {
        data.accountManager = this.accountManager;
      } else if (this.accountManager && typeof this.accountManager === 'object' && 'id' in this.accountManager) {
        data.accountManager = this.accountManager.id;
      }
    }

    if (this.dealer) {
      data.dealer = this.dealer;
    }

    return data;
  }

}
