




























import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {QueryAutocomplete},
})
export default class InputTypeType extends Mixins(InputMixin) {
  protected focus: boolean = false;

  @Prop(String) private make?: string;

  @Watch('focus')
  private onFocusChanged() {
    if (this.focus) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }
}
